import React, { Fragment, useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import List from "@material-ui/core/List";
// import ListItem from "@material-ui/core/ListItem";
import Box from "@material-ui/core/Box";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import LaunchIcon from "@material-ui/icons/Launch";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import KeyboardArrowUpOutlinedIcon from "@material-ui/icons/KeyboardArrowUpOutlined";

import EsgGptCompanyInfo, {
  HeadingCard,
} from "./EsgGptCompany/EsgGptCompanyInfo";
import { TopicHeader, TopicData } from "../../esgCompanyProfile/esgComponents";
import ScoreChart from "../../esgCompanyProfile/ScoreChart";
import { OverallRiskCard } from "../../esgCompanyProfile/overallProfile";
import thumbUp from "../../../../images/thumbUp.svg";
import thumbDown from "../../../../images/thumbDown.svg";
import copySvg from "../../../../images/copy.svg";
import more from "../../../../images/more.svg";
import share from "../../../../images/share.svg";
import heart from "../../../../images/heart.png";
import companyPlaceholder from "../../../../images/CompanyPlaceholder.png";

import PercentageDoughnutChart from "../../../UI/percentageDoughnut.js/percentageDoughnutChart";
import CustomButton from "../../../UI/button/button";
import Charts, { LineCharts } from "./EsgGptCompany/SampleCharts";
import EsgGptChatMessageLayout from "../esgGptChat/EsgGptChatMessageLayout";

import {
  getCompanyInsights,
  getScores,
  getPeerScores,
} from "../../../../redux/actions/riskEvaluator/riskEvaluatorActions";
import { ListItem } from "../EsgGptCompanyList";
import CustomCompaniesRange from "../../../UI/CustomRange/CustomCompaniesRange";
import { EsgAnainsPeersChart } from "../../charts/charts";
import chevronsLeft from "../../../../images/chevronsLeft.png";
import airTable from "../../../../images/airtable.png";
import freetrial from "../../../../images/freetrail.png";
import mcd from "../../../../images/mcd.png";
import useChat from "../esgGptChat/useChat";
import {
  getGptChatHIstory,
  setChatConversations,
  setDisclaimer,
} from "../../../../redux/actions/gpt/esgGptAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getControversyLinks } from "../../../../redux/actions/riskdashboard/riskDashboardActions";
import EnergyCharts from "./EsgGptCompany/EnergyCharts";
import CompanyLoading from "../CompanyLoading";
import { Button, Dialog, DialogContent, Paper } from "@material-ui/core";
import {
  gptPeerAnalysisText,
  returnCategoryOfdataPoint,
} from "../../../../utils/riskUtils/riskUtils";

// Socket imports
import {
  connectSocket,
  connectSocketCompany,
  socket,
  socketCompany,
} from "../../../../socket";
import UpdatedDisclaimer from "./EsgCompanyRightComps/UpdatedDisclaimer";
import PlanExpired from "../PlanExpired";
import Feedback from "../Feedback";
import { setSignupStepNo } from "../../../../redux/actions/login/loginAction";
import { set_snack_bar } from "../../../../redux/actions/snackbar/snackbar_action";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#EBF1F8",
    // color: theme.palette.common.black,
    fontWeight: 500,
    color: "#15314E",
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: "#15314E",
  },
}))(TableCell);

function createData(srNo, name, calories, fat, carbs, protein) {
  return { srNo, name, calories, fat, carbs, protein };
}

const rows = [
  createData("Total Electricity Consumption", 51511, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
  createData("Total Fuel Consumption", 65464, 564654),
];

const useStyles = makeStyles({
  table: {
    // minWidth: 700,
    marginTop: "10px",
    width: "100%",
  },
  tableCell: {
    padding: "5px",
  },
  titleRow: {
    backgroundColor: "#F6F6F6",
  },
  typoColorSecondary: {
    color: "#11184F",
  },
  suggestion: {
    backgroundColor: "#f6f6f6",
    border: `1px solid "#EAEEF5"`,
    borderRadius: "4px",
    "&:hover": {
      backgroundColor: "#EBF1F8",
    },
  },
  dialog: {
    maxWidth: 550,
    position: "absolute",
    backgroundColor: "white",
    borderRadius: 8,
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    padding: 10,
    boxShadow: "1px 1px 4px grey",
  },
});

export function BlueBackgroundCard({
  heading,
  subHeading,
  sideHeading,
  is_premium,
  handleClosePricingDialog,
}) {
  return (
    <div
      style={{
        // height: "50px",
        width: "100%",
        backgroundColor: "#EBF1F8",
        display: "flex",
        flexDirection: sideHeading || is_premium === false ? "row" : "column",
        alignItems: is_premium === false ? "center" : "flex-start",
        marginTop: "10px",
        justifyContent: "space-between",
      }}
    >
      <Typography
        style={{
          fontSize: "14px",
          fontWeight: 500,
          color: "#15314E",
          lineHeight: "20px",
          padding: subHeading ? "15px 15px 10px 15px" : "15px",
        }}
      >
        {heading}
      </Typography>
      {subHeading && (
        <Typography
          style={{
            fontSize: "13px",
            fontWeight: 400,
            color: "#676767",
            padding: "0px 15px 15px 15px",
          }}
        >
          {subHeading}
        </Typography>
      )}
      {sideHeading && (
        <Typography
          style={{
            fontSize: "14px",
            fontWeight: 500,
            lineHeight: "20px",
            color: "#15314E",
            padding: "15px",
            marginLeft: "auto",
          }}
        >
          {subHeading || sideHeading}
        </Typography>
      )}
      {is_premium === false && (
        <Button
          variant="contained"
          style={{
            color: "#1976D2",
            backgroundColor: "white",
            marginRight: "10px",
          }}
          size="small"
          onClick={() => handleClosePricingDialog(heading)}
          disableElevation={true}
        >
          View Details
        </Button>
      )}
    </div>
  );
}

export function KpiPresentation({
  chartsArr,
  width = "100%",
  chartWidth = "48%",
  is_premium = true,
  kpiChartdata,
}) {
  const classes = useStyles();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      {/* {kpiChartdata &&
        Object.entries(kpiChartdata).map((ent) => {
          return (
            <div style={{ width: chartWidth, height: "300px" }}>
              <EnergyCharts kpiChartdata={ent} />

             <Charts /> 
            </div>
          );
        })} */}
      <div style={{ height: "400px", width: "100%" }}>
        {<EnergyCharts kpiChartdata={kpiChartdata} is_premium={is_premium} />}
      </div>
      <div style={{ width }}>
        <TableContainer
          style={{
            padding: "3px",
            // width:"70%"
          }}
          component={"div"}
        >
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow style={{}}>
                <StyledTableCell className={classes.tableCell}>
                  Topics
                </StyledTableCell>
                <StyledTableCell className={classes.tableCell}>
                  Current Year
                </StyledTableCell>
                {/* <StyledTableCell className={classes.tableCell}>
                  Previous Year
                </StyledTableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {kpiChartdata &&
                Object.entries(kpiChartdata).map(([key, value]) => (
                  <TableRow
                    key={key}
                    className={true ? classes.titleRow : classes.topicRow}
                  >
                    <StyledTableCell
                      component="th"
                      scope="row"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        padding: true ? 10 : 8,
                      }}
                    >
                      {key}
                    </StyledTableCell>
                    <StyledTableCell
                      className={classes.tableCell}
                      style={{ filter: is_premium ? "blur(0px)" : "blur(5px)" }}
                    >
                      {value}
                    </StyledTableCell>
                    {/* <StyledTableCell className={classes.tableCell}>
                    {row.calories}
                  </StyledTableCell> */}
                  </TableRow>
                ))}
              {/* {rows.map((row) => (
                <TableRow
                  key={row.name}
                  className={row.srNo ? classes.titleRow : classes.topicRow}
                >
                  <StyledTableCell
                    component="th"
                    scope="row"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: row.srNo ? 10 : 8,
                    }}
                  >
                    {row.srNo}
                  </StyledTableCell>
                  <StyledTableCell className={classes.tableCell}>
                    {row.name}
                  </StyledTableCell>
               <StyledTableCell className={classes.tableCell}>
                    {row.calories}
                  </StyledTableCell> 
                </TableRow>
              ))} */}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export function EsgInsightCard({ topic, insight = [] }) {
  // console.log("EsgInsightCard", insight);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
        gap: "10px",
        marginTop: 10,
      }}
    >
      <OverallRiskCard
        // topic={topic.topic}
        // heading={topic.heading}
        insight={insight}
        gpt={true}
      />
      {false &&
        insight &&
        insight.length > 0 &&
        insight.map((topic, i) => {
          let type = Object.keys(topic)[0];
          let topicHeader = Object.values(topic)[0];
          // console.log("topicHeader", i, type);
          let category = returnCategoryOfdataPoint(type);
          return (
            <OverallRiskCard
              topic={category || "Environment"}
              heading={type}
              description={topicHeader}
              gpt={true}
              key={i}
            />
          );
        })}
      {false &&
        insight.length > 0 &&
        insight.map(([key, value], index) => {
          let type = key;
          let topicHeader = value;
          // console.log("topicHeader", i, type);
          let category = returnCategoryOfdataPoint(type);
          return (
            <OverallRiskCard
              topic={category || "Environment"}
              heading={type}
              description={topicHeader}
              gpt={true}
              key={index}
            />
          );
        })}
    </div>
  );
}

export function EsgInsightMain({ title, area, color, children }) {
  // console.log("EsgInsightMain", { title, area, color, children });
  return (
    <div style={{ padding: "15px" }}>
      <HeadingCard primaryHead={title}>
        <IconButton
          variant="outlined"
          style={{
            borderColor: "#FBFBFB",
            backgroundColor: color,
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
        >
          {children}
        </IconButton>
      </HeadingCard>

      <EsgInsightCard insight={area} />
    </div>
  );
}

export function MoreActionButtons(props) {
  const { chatId, answer } = props;
  const [openFeedBackDialog, setOpenFeedBackDialog] = React.useState(false);
  const [dislike, setDislike] = React.useState(false);
  console.log("MoreActionButtons", props, chatId);
  const dispatch = useDispatch();

  const handleCloseFeedBackDialog = (value) => {
    if (value) {
      setDislike(true);
      setOpenFeedBackDialog((preValue) => !preValue);
    } else {
      setOpenFeedBackDialog((preValue) => !preValue);
      setDislike(false);
    }
  };

  const handleCopyText = () => {
    if (answer) {
      navigator.clipboard.writeText(answer);
      dispatch(set_snack_bar(true, "Text copied to clipboard!"));
    }
  };

  return (
    <>
      <div
        style={{
          // width: "300px",
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          // border:"solid"
        }}
      >
        {answer && (
          <IconButton
            variant="contained"
            style={{
              marginLeft: "10px",
              padding: "5px",
              borderRadius: 5,
            }}
            size="small"
            onClick={handleCopyText}
          >
            <img
              src={copySvg}
              style={{
                margin: "-5px",
                width: "50px",
                height: "50px",
                cursor: "pointer",
              }}
              alt="copy icon"
            />
          </IconButton>
        )}
        <IconButton
          variant="contained"
          style={{
            // borderColor: "#FBFBFB",
            // backgroundColor: "#EDF2F3",
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
          onClick={() => handleCloseFeedBackDialog(false)}
        >
          <img src={thumbUp} style={{ cursor: "pointer" }} alt="pdf icon" />
        </IconButton>
        <IconButton
          variant="contained"
          style={{
            // borderColor: "#FBFBFB",
            // backgroundColor: "#EDF2F3",
            marginLeft: "10px",
            padding: "5px",

            borderRadius: 5,
          }}
          size="small"
          onClick={() => handleCloseFeedBackDialog(true)}
        >
          <img src={thumbDown} style={{ cursor: "pointer" }} alt="pdf icon" />
        </IconButton>
        {/* <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={more} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton>
      <IconButton
        variant="contained"
        style={{
          // borderColor: "#FBFBFB",
          // backgroundColor: "#EDF2F3",
          marginLeft: "10px",
          padding: "5px",

          borderRadius: 5,
        }}
        size="small"
      >
        <img src={share} style={{ cursor: "pointer" }} alt="pdf icon" />
      </IconButton> */}
      </div>
      {openFeedBackDialog && (
        <Feedback
          isOpen={openFeedBackDialog}
          handleClose={handleCloseFeedBackDialog}
          additional={dislike}
          chatId={chatId}
          title={"Please Provide Additional Feedback"}
          contentText={
            "What was the issue with the response ? How could it be improved ?What was the issue with the response ? How could it be improved ?"
          }
          extraText={"Let us help you better on the basis of your rating"}
          placeHolderText={
            dislike
              ? "What do you not like about the response ?"
              : "What do you like about the response ?"
          }
        />
      )}
    </>
  );
}

export function ChatPlayGround({ companyName }) {
  const history = useHistory();

  useEffect(() => {
    connectSocket();
    connectSocketCompany();

    // Clean up on component unmount
    return () => {
      if (socket) {
        socket.disconnect();
      }
      if (socketCompany) {
        socketCompany.disconnect();
      }
    };
  }, [companyName]);

  useEffect(() => {
    if (socket) {
      // Handle socket connection
      socket.on("connect", () => {
        console.log("Connected to Socket.io Server");
      });
    } else {
      console.log("Unable to connect to socket server");
    }

    // Socket connection for Company chat
    if (socketCompany) {
      // Handle socket connection
      socketCompany.on("connect", () => {
        console.log("Connected to SocketCompany Server");
      });
    } else {
      console.log("Unable to connect to SocketCompany Server");
    }
  }, [socketCompany, socket]);

  const { location } = history;
  const { chats, isDisclaimer } = useSelector((state) => state.esgGptReducer);

  const chatAutoSuggestion = [
    `Net Zero Operations for ${companyName}`,
    `Diversity and Inclusion for ${companyName}`,
    `ESG achievements for ${companyName}`,
    `Summarize on employee engagement and well being for ${companyName}`,
  ];

  const chatSuggestion = [
    `Come up with a concept for ESG`,
    `Recommend a framework`,
    `Compare ESG Principles`,
  ];
  const chatSuggestionCOndition =
    location.pathname === "/esg_gpt/chat" ? chatSuggestion : chatAutoSuggestion;

  const { handleChats } = useChat();
  const [chatInitiate, setChatInitiate] = React.useState(false);

  const chat_tokens = JSON.parse(localStorage.getItem("chat_tokens"));

  // plan expired states
  const [planExpired, setPlanExpired] = useState(false);

  const togglePlanExpired = () => {
    setPlanExpired(!planExpired);
  };

  const handleChatSuggestion = (question) => {
    if (!chat_tokens || chat_tokens <= 0) {
      setPlanExpired(true);
    } else {
      setChatInitiate(true);
      handleChats(question, companyName);
    }
  };
  const dispatch = useDispatch();
  // const [isDesclaimerOpen, setIsDesclaimerOpen] = useState(false);
  // console.log('chat play ground',isDisclaimer,JSON.parse(localStorage.getItem("isDisclaimer")))

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("isDisclaimer")) && chats.length > 0) {
      dispatch(setDisclaimer(true));
    }
  }, [isDisclaimer, chats]);

  const classes = useStyles();

  const Disclaimer = () => {
    return (
      <Dialog open={isDisclaimer} style={{ minW: "100%" }}>
        <Typography
          style={{
            padding: "10px 10px",
            fontSize: 14,
            fontWeight: 600,
            borderBottom: "1px solid #AFAFAF",
          }}
        >
          Disclaimer
        </Typography>
        <DialogContent>
          <div componet={Paper}>
            <div style={{ padding: "30px 0px" }}>
              <Typography
                style={{ fontSize: 13, fontWeight: 400, color: "#AFAFAF" }}
              >
                All the information on this app is published in good faith and
                for general information purpose only. Imgrows do not make any
                warranties about the completeness, reliability and accuracy of
                this information.
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                position: "relative",
                bottom: 0,
                left: 0,
                right: 0,
                width: "100%",
                paddingBottom: "5px",
              }}
            >
              {/* <CustomButton
              onClick={() => {
                // setWarning(false);
                // onClose();
              }}
              fullwidth
              style={{
                textTransform: "none",
                padding: "3px 18px",
                borderRadius: "8px",
                fontSize: 13,
                // marginRight: "10px",
                border: "none",
                width: "125px",
              }}
              color="primary"
              variant="outlined"
            >
              Close
            </CustomButton> */}
              <CustomButton
                onClick={() => {
                  localStorage.setItem("isDisclaimer", false);
                  dispatch(setDisclaimer(false));
                }}
                style={{
                  textTransform: "none",
                  padding: "3px 18px",
                  borderRadius: "8px",
                  fontSize: 13,
                  "&:hover": {
                    boxShadow: "1px 1px 4px grey",
                  },
                }}
                color="primary"
                variant="contained"
              >
                Continue
              </CustomButton>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      {<Disclaimer />}
      {planExpired && (
        <PlanExpired isOpen={planExpired} handleClose={togglePlanExpired} />
      )}
      {chats.length === 0 && (
        // <div
        //   style={{
        //     display: "flex",
        //     flexWrap: "wrap",
        //     gap: "20px",
        //     justifyContent: "center",
        //   }}
        // >
        //   {chatAutoSuggestion.map((suggestion) => (
        //     <div
        //       style={{ width: "30%", textAlign: "center", cursor: "pointer" }}
        //       onClick={() => handleChatSuggestion(suggestion)}
        //     >
        //       <HeadingCard primaryHead={suggestion} justifyCenter={true} />
        //     </div>
        //   ))}
        // </div>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            fontFamily: "poppins",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            {chatSuggestionCOndition.map((suggestion, index) => (
              <div
                style={{
                  width: "32%",
                  textAlign: "center",
                  cursor: "pointer",
                  // border: "solid",
                }}
                onClick={() => handleChatSuggestion(suggestion)}
                className={classes.suggestion}
                key={index}
              >
                <Typography
                  className={classes.typoColorSecondary}
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    // color: "#242424",
                    padding: "15px",
                    lineHeight: "20px",
                    fontFamily: "Poppins",
                  }}
                >
                  {suggestion}
                </Typography>
              </div>
            ))}
          </div>
          {/* Updated Disclaimer */}
          <UpdatedDisclaimer />
        </Box>
      )}
      {(chatInitiate || chats.length > 0) && (
        <EsgGptChatMessageLayout
          companyName={companyName}
          setPlanExpired={setPlanExpired}
        />
      )}
    </>
  );
}

function EsgGptCompanyRight() {
  const login = useSelector((state) => state.login);

  const history = useHistory();

  const { location } = history;

  const is_company_based = location.pathname === "/esg_gpt/chat" ? false : true;

  const riskEvaluator = useSelector((state) => state.riskEvaluator);

  const { newOrganization, selectedChat } = useSelector(
    (state) => state.esgGptReducer
  );
  const { controversyLinks } = useSelector((state) => state?.riskDashboard);
  const { scoreObject, esgScore, esgcScore, energy_kpis, water_kpis } = {
    ...riskEvaluator?.scores,
  };
  const { environment, social, governance } = { ...scoreObject };
  // console.log("newOrganization", newOrganization);
  const {
    final_esg_score,
    max_esg_score,
    min_esg_score,
    peer_companies,
    year_wise_data,
  } = {
    ...riskEvaluator?.peerScores,
  };

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("signup_process_no")) === 3) {
      dispatch(setSignupStepNo(4));
    }
  }, []);
  // const [overallData] = riskEvaluator?.overallData;
  // console.log("riskEvaluator?.overallData", riskEvaluator?.overallData);
  // const { Company, ["Risky Areas Summary"]: riskAreaSummary } = {
  //    ...riskEvaluator?.overallData
  // }
  // console.log(
  //   "riskEvaluator?.peerScores",
  //   riskEvaluator?.peerScores?.peer_companies
  // );
  // const { organizations } = useSelector((state) => state.esgGptReducer);
  const dispatch = useDispatch();

  const [openPricingDialog, setOpenPricingDialog] = React.useState(false);
  const [sectionName, setSectionName] = React.useState(false);

  const handleClosePricingDialog = (source) => {
    setSectionName(source);
    setOpenPricingDialog((preValue) => !preValue);
  };
  // console.log("newOrganization", newOrganization);

  // const ins = {
  //   strong_areas_summary: [
  //     {
  //       "Based on the information provided in the context, the following are possible answers to the question":
  //         "",
  //     },
  //     {
  //       "Analytic CO2":
  //         'The report mentions "Analytic CO2" on page 80, but there is no further information provided. Therefore, it is not possible to answer this question.',
  //     },
  //     {
  //       "Green revenues":
  //         'The report does not mention "Green revenues" anywhere. Therefore, it is not possible to answer this question.',
  //     },
  //     {
  //       "Career development and training":
  //         'The report mentions "Career development and training" on page 45-47, but there is no further information provided. Therefore, it is not possible to answer this question.',
  //     },
  //     {
  //       "Health and Safety":
  //         'The report mentions "Health and Safety" on page 61, but there is no further information provided. Therefore, it is not possible to answer this question.',
  //     },
  //     { "Data privacy": 'The report mentions "Data' },
  //   ],
  //   weak_areas_summary: [
  //     {
  //       "Based on the information provided, here is a list of possible answers to the question \"According to ['Energy', 'Water', 'Waste', 'HumanRights', 'Compensation', 'EnvtScore', 'Governance Score', 'Controversy score', 'Final ESG score']\"":
  //         "",
  //     },
  //     {
  //       "1. Energy":
  //         "The company's energy consumption and greenhouse gas emissions, including the percentage of renewable energy sources used.",
  //     },
  //     {
  //       "2. Water":
  //         "The company's water consumption and withdrawal in water-stressed areas, including the percentage of water recycled or reused.",
  //     },
  //     {
  //       "3. Waste":
  //         "The company's waste management practices, including the percentage of waste recycled or reused.",
  //     },
  //     {
  //       "4. HumanRights":
  //         "The company's policies and practices related to human rights, including labor standards and diversity and inclusion.",
  //     },
  //     { "5. Compensation": "The company's compensation" },
  //   ],
  //   // improvemnet_areas_summary: [
  //   //   {
  //   //     "Diversity and Inclusion:": [
  //   //       "Fostering a culture of inclusion and diversity in all aspects of the business",
  //   //       "Ensuring equal opportunities for all employees, regardless of background or identity",
  //   //       "Promoting diversity and inclusion in hiring practices and talent development",
  //   //       "Providing training and resources to support diverse and inclusive workplace culture",
  //   //     ],
  //   //   },
  //   //   {
  //   //     "Responsible Marketing:": [
  //   //       "Ensuring that marketing practices are responsible and respectful of all stakeholders",
  //   //       "Promoting ethical and sustainable marketing practices",
  //   //       "Ensuring transparency and accountability in marketing activities",
  //   //       "Respecting consumer privacy and data security",
  //   //     ],
  //   //   },
  //   // ],
  // };
  React.useEffect(() => {
    if (newOrganization?.isin) {
      dispatch(getScores(login.token, newOrganization?.isin));
      dispatch(
        getCompanyInsights(
          login.token,
          newOrganization?.isin,
          newOrganization?.year
        )
      );
      dispatch(
        getPeerScores(
          login?.token,
          newOrganization?.isin,
          newOrganization?.industry_code,
          newOrganization?.year
        )
      );
      dispatch(
        getGptChatHIstory(
          login?.token,
          newOrganization?._id === undefined ? "" : newOrganization?._id,
          is_company_based,
          selectedChat?._id === undefined ? "" : selectedChat?._id
        )
      );
      // dispatch(getCompanyInsights(login.token, newOrganization?.isin,newOrganization?.year_of_assessment));
      dispatch(getControversyLinks(login.token, newOrganization?.isin, 5, 2,newOrganization?.year));
    }

    return () => {
      dispatch(setChatConversations([]));
    };
  }, [newOrganization?.isin]);

  // temporary

  const dataNew = [
    {
      topic: "Environment",
      score: scoreObject?.environment?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Energy",
          score: scoreObject?.environment?.data_points?.energy?.toFixed(2),
        },
        {
          topic: "Water",
          score: scoreObject?.environment?.data_points?.water?.toFixed(2),
        },
        {
          topic: "Waste",
          score: scoreObject?.environment?.data_points?.waste?.toFixed(2),
        },
        {
          topic: "Analytic CO2",
          score: scoreObject?.environment?.data_points?.analytic?.toFixed(2),
        },
        {
          topic: "Green revenues",
          score: scoreObject?.environment?.data_points?.green?.toFixed(2),
        },
      ],
    },
    {
      topic: "Social",
      score: scoreObject?.social?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Diversity and Inclusion",
          score: scoreObject?.social?.data_points?.diversity?.toFixed(2),
        },
        {
          topic: "Human Rights",
          score: scoreObject?.social?.data_points?.humanRights?.toFixed(2),
        },
        {
          topic: "Career development and training",
          score: scoreObject?.social?.data_points?.career?.toFixed(2),
        },
        {
          topic: "Health and Safety",
          score: scoreObject?.social?.data_points?.health?.toFixed(2),
        },
        {
          topic: "Data privacy",
          score: scoreObject?.social?.data_points?.dataPrivacy?.toFixed(2),
        },
        {
          topic: "Responsible Marketing",
          score: scoreObject?.social?.data_points?.product?.toFixed(2),
        },
      ],
    },
    {
      topic: "Governance",
      score: scoreObject?.governance?.score?.toFixed(2),
      subTopic: [
        {
          topic: "Structure",
          score: scoreObject?.governance?.data_points?.structure?.toFixed(2),
        },
        {
          topic: "Compensation",
          score: scoreObject?.governance?.data_points?.compensation?.toFixed(2),
        },
      ],
    },
    // {
    //   topic: "Business",
    //   score: 30,
    //   subTopic: [
    //     { topic: "Product Quality and Safety", score: 10 },
    //     { topic: "Customer Privacy and Data Protection", score: 6 },
    //     { topic: "Supply Chain Management", score: 28 },
    //     { topic: "Marketing and Advertising", score: 25 },
    //     { topic: "Innovation and Intellectual Property", score: 3},
    //   ],
    // },
    // {
    //   topic: "Human Capital",
    //   score: 7,
    //   subTopic: [
    //     { topic: "Work-Life Balance", score: 2 },
    //     { topic: "Employment Training & Development", score: 2 },
    //     { topic: "Diversity and Inclusion", score: 1 },
    //     { topic: "Employee engagement", score: 2 },
    //   ],
    // },
  ];

  const data = [
    {
      type: "The Top 6 Environmental Issues in Business",
      logo: mcd,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "Responsible Sourcing",
      logo: airTable,
      topic: "Responsible Sourcing",
      link: "https://www.prodigium-pictures.com/blog/environmental-issues-in-business",
    },
    {
      type: "Annual Report",
      logo: chevronsLeft,
      topic: "The Top 6 Environmental Issues in Business",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
    {
      type: "The Top 6 Environmental Issues in Business",
      logo: freetrial,
      topic: "Annual Report 2020",
      link: "https://www.luxotica.tech.com/luxotica-annual-report-2022/sustainability.php",
    },
  ];

  const is_premium = JSON.parse(localStorage.getItem("is_premium")) ;

  return (
    <div
      style={{
        height: newOrganization?.loader ? "100%" : "88%",
        width: "100%",
        overflow: "scroll",
        marginBottom: "1%",
        padding: "5px",
        // border:"solid",
        // backgroundColor:"red"
      }}
    >
      <div
        style={{
          // height: "25vh",
          width: "100%",
        }}
      >
        <EsgGptCompanyInfo
          esgScore={esgScore}
          esgCScore={esgcScore}
          is_premium={is_premium}
        />
      </div>
      {newOrganization?.loader ? (
        <CompanyLoading margin={2} />
      ) : (
        <>
          <BlueBackgroundCard
            heading={"ESG Profile With ESG Score"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          />
          <div
            style={{
              // height: "95%",
              width: "100%",
              padding: "15px",
              display: "flex",
              // justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "space-between",
                gap: "5px",
                width: "60%",
                // border:"solid"
              }}
            >
              {[...dataNew, 1].map((data, index, arr) => {
                const lastElement = index === arr.length - 1;
                return (
                  <Fragment key={`uniqueKey${index}`}>
                    {lastElement ? (
                      <div style={{ width: "48%" }} key={index}>
                        <BlueBackgroundCard heading={"ESG Score"} />
                        <Typography
                          style={{
                            fontSize: "20px",
                            fontWeight: 600,
                            color: "#242424",
                            padding: "10px",
                            filter: is_premium ? "blur(0px)" : "blur(5px)",
                          }}
                        >
                          {esgScore?.toFixed(2)}%
                        </Typography>
                      </div>
                    ) : (
                      <div style={{ width: "48%" }} key={index}>
                        <TopicHeader
                          topic={data.topic}
                          score={data.score}
                          is_premium={is_premium}
                        />
                        {data.subTopic.map((sub, index) => (
                          <TopicData
                            topic={sub.topic}
                            score={sub.score}
                            key={index}
                            is_premium={is_premium}
                          />
                        ))}
                      </div>
                    )}
                  </Fragment>
                );
              })}
              {/* <div style={{ width: "48%" }}>
              <BlueBackgroundCard heading={"ESG Score"} />
              <Typography
                style={{
                  fontSize: "20px",
                  fontWeight: 600,
                  color: "#242424",
                  padding: "5px",
                }}
              >
                {esgScore?.toFixed(2)}%
              </Typography>
            </div> */}
            </div>
            <div
              style={{
                display: "flex",
                padding: "50px 20px 50px 10px",
                // border:"solid",
                width: "35%",
              }}
            >
              {riskEvaluator?.scores && (
                <ScoreChart
                  scores={{ ...riskEvaluator?.scores }}
                  is_premium={is_premium}
                />
              )}
            </div>
          </div>
          <BlueBackgroundCard
            heading={" ESG Controversy Score (ESGC)"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "48%",
                padding: "15px",
              }}
            >
              <HeadingCard primaryHead={"Online Footprint Score"} />

              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ padding: 10 }}>
                  <img src={heart} alt="heart" />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "20%",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "#ECA270",
                        borderRadius: "100%",
                        width: "12px",
                        height: "12px",
                      }}
                    />
                    <Typography style={{ fontSize: 13, padding: 10 }}>
                      Online Foot Print Score.
                    </Typography>
                  </div>
                  {/* <Typography
                    style={{ fontSize: 13, fontWeight: 600, padding: 10 }}
                  >
                    {`${
                      Math.round(riskEvaluator?.scores?.esgcScore) || 0
                    } / 100`}
                  </Typography> */}
                  <Typography
                    style={{
                      fontSize: "13px",
                      fontWeight: 600,
                      color: "#242424",
                      padding: "12px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#242424",
                        paddingLeft: "2px",
                        filter: is_premium ? "blur(0px)" : "blur(5px)",
                      }}
                    >
                      {Math.round(riskEvaluator?.scores?.esgcScore) || 0}
                    </span>
                    <span
                      style={{
                        fontSize: "13px",
                        fontWeight: 600,
                        color: "#242424",
                        paddingLeft: "2px",
                      }}
                    >
                      {` / ${100}`}
                    </span>
                  </Typography>
                </div>

                <div>
                  <PercentageDoughnutChart
                    color="#ECA270"
                    emptyColor="#F9EEED"
                    footPrint={true}
                    percentage={
                      Math.round(riskEvaluator?.scores?.esgcScore) || 0
                    }
                    is_premium={is_premium}
                  />
                </div>
              </div>

              {controversyLinks && controversyLinks.length > 0 && (
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <Typography style={{ fontSize: 13 }}>
                      These search findings illuminate the ongoing controversies
                      and divergent viewpoints within the corporate landscape
                      regarding your ESG policies. Investigate the varying
                      opinions on how businesses should navigate and respond to
                      environmental challenges, delving into controversies
                      surrounding corporate environmental responsibility and
                      sustainability from a company-centric perspective
                    </Typography>
                    {/* <Typography style={{ fontSize: 13, marginTop: 8 }}>
                    Based on our market insights and benchmarking tool, is in
                    the second quartile of the Peer group range and has
                    significant scope to improve.
                  </Typography>
                  <Typography style={{ fontSize: 13, marginTop: 8 }}>
                    Our curated sustainability solutions are designed to help
                    you progress your company’s sustainability goals to the next
                    level.
                  </Typography> */}
                  </div>
                  <div></div>
                </div>
              )}
            </div>
            <div
              style={{
                width: "48%",
                padding: "15px",
              }}
            >
              <HeadingCard primaryHead={"Reference Link"} />

              <List
                style={{
                  height: "325px",
                  overflow: "scroll",
                  width: true ? "100%" : "430px",
                }}
              >
                {controversyLinks &&
                  controversyLinks.map((v, index) => {
                    return (
                      <ListItem style={{ padding: 5 }} key={index}>
                        <ListItemAvatar>
                          <img
                            src={v?.socialimage || companyPlaceholder}
                            style={{ padding: "0px 5px" }}
                            width={"70px"}
                            height={"100%"}
                            alt="star"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 12,
                                fontWeight: 500,
                                color: "#000000",
                              }}
                            >
                              {v?.title}
                            </Typography>
                          }
                          secondary={
                            <Typography
                              variant="body2"
                              style={{
                                fontSize: 11,
                                color: "#6F7170",
                                width: "80%",
                                overflow: "hidden",
                              }}
                            >
                              {v?.url}
                            </Typography>
                          }
                        />
                        <ListItemAvatar style={{ padding: 5 }} align="center">
                          <IconButton
                            style={{ padding: 5 }}
                            edge="end"
                            aria-label="delete"
                          >
                            <LaunchIcon
                              onClick={() => window.open(v.url)}
                              style={{ color: "#3374B9" }}
                            />
                          </IconButton>
                        </ListItemAvatar>
                      </ListItem>
                    );
                  })}
              </List>
            </div>
          </div>
          <BlueBackgroundCard
            heading={"ESG KPI Data Breakdown With Data Representation"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          />
          <div style={{ padding: "15px" }}>
            <HeadingCard primaryHead={"Energy"} />

            <KpiPresentation
              chartsArr={[1, 2]}
              kpiChartdata={energy_kpis}
              is_premium={is_premium}
            />
          </div>

          <div style={{ padding: "15px" }}>
            <HeadingCard primaryHead={"Water"} />
            <KpiPresentation
              kpiChartdata={water_kpis}
              chartsArr={[1]}
              width={"65%"}
              chartWidth={"33%"}
              is_premium={is_premium}
            />
          </div>

          <BlueBackgroundCard
            heading={"ESG Insights"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          />
          <EsgInsightMain
            title={"ESG Risk Area"}
            area={riskEvaluator?.overallData?.["weak_areas_summary"]}
            color={"crimson"}
          >
            <ReportProblemOutlinedIcon
              style={{ color: "#FBFBFB" }}
              fontSize="small"
            />
          </EsgInsightMain>
          <EsgInsightMain
            title={"Strong Area"}
            area={riskEvaluator?.overallData?.["strong_areas_summary"]}
            color={"lightgreen"}
          >
            <CheckOutlinedIcon style={{ color: "#FBFBFB" }} fontSize="small" />
          </EsgInsightMain>
          <EsgInsightMain
            title={"Improvement Area"}
            area={riskEvaluator?.overallData?.["improvement_areas_summary"]}
            color={"#4169E1"}
          >
            <KeyboardArrowUpOutlinedIcon
              style={{ color: "#FBFBFB" }}
              fontSize="small"
            />
          </EsgInsightMain>
          <BlueBackgroundCard
            heading={"Peer Analysis"}
            is_premium={is_premium}
            handleClosePricingDialog={handleClosePricingDialog}
          />
          {/* <div>
          <CustomCompaniesRange
            companyScores={[30, 40, 45, 50, 60]}
            score={final_esg_score?.score.toFixed(2)}
            lowerRange={min_esg_score?.score?.toFixed(2)}
            upperRange={max_esg_score?.score?.toFixed(2)}
          />
        </div> */}
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <div style={{ width: "45%" }}>
              <CustomCompaniesRange
                fullwidth={false}
                peer_companies={peer_companies}
                score={final_esg_score?.score?.toFixed(2)}
                lowerRange={min_esg_score?.score?.toFixed(2)}
                upperRange={max_esg_score?.score?.toFixed(2)}
                is_premium={is_premium}
              />
              {/* <CustomRange
              score={final_esg_score?.score.toFixed(2)}
              lowerRange={min_esg_score?.score?.toFixed(2)}
              upperRange={max_esg_score?.score?.toFixed(2)}
            /> */}
            </div>
            <div
              style={{
                width: "45%",
                filter: is_premium ? "blur(0px)" : "blur(5px)",
              }}
            >
              <Typography
                style={{
                  paddingLeft: 15,
                  fontWeight: 500,
                  marginTop: "2px",
                  fontSize: "14px",
                  color: "#333F48",
                }}
              >
                Based on our market insights and benchmarking tool,
              </Typography>
              <Typography
                style={{
                  paddingLeft: 15,
                  color: "#333F48",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginTop: "4px",
                }}
              >
                {` Your company is in the
                    ${
                      final_esg_score?.score <= 25
                        ? "first Quartile"
                        : final_esg_score?.score > 25 &&
                          final_esg_score?.score <= 50
                        ? "second quartile"
                        : final_esg_score?.score > 50 &&
                          final_esg_score?.score < 75
                        ? "third Quartile"
                        : "fourth quartile"
                    }
                    of the Peer group range and has significant scope to improve.`}
              </Typography>
              <Typography
                style={{
                  paddingLeft: 15,
                  fontWeight: 500,
                  marginTop: "2px",
                  fontSize: "14px",
                  color: "#333F48",
                }}
              >
                Our curated sustainability solutions are designed to help you
                progress your company’s sustainability goals to the next level.
              </Typography>

              {gptPeerAnalysisText().map((topic) => {
                return (
                  <Typography
                    key={topic}
                    style={{
                      paddingLeft: 15,
                      fontSize: "13px",
                      color: "#333F48",
                      marginTop: "15px",
                    }}
                  >
                    {topic}
                  </Typography>
                );
              })}
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "380px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "300px",
                filter: is_premium ? "blur(0px)" : "blur(5px)",
              }}
            >
              {/* <EsgAnainsPeersChart /> */}

              <Charts isPeer={true} peer_companies={peer_companies} />
            </div>
          </div>
          <div
            style={{
              width: "100%",
              height: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // flexWrap: "wrap",
            }}
          >
            <div
              style={{
                width: "90%",
                height: "300px",
                filter: is_premium ? "blur(0px)" : "blur(5px)",
              }}
            >
              <LineCharts year_wise_data={year_wise_data} />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#242424",
                padding: "15px",
              }}
            >
              {/* Would you like to share these data */}
              Would you like to give us feedback on the above data
            </Typography>
            {/* <MoreActionButtons chatId={chatId} answer={answer}  /> */}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#242424",
                padding: "15px",
              }}
            >
              {`Ask a question about ${newOrganization?.name}...`}
            </Typography>
          </div>
          <div style={{ padding: "15px" }}>
            <ChatPlayGround companyName={newOrganization?.name} />
          </div>
        </>
      )}
      {openPricingDialog && (
        <PlanExpired
          isOpen={openPricingDialog}
          handleClose={handleClosePricingDialog}
          sourceName={sectionName}
        />
      )}
    </div>
  );
}

export default EsgGptCompanyRight;
