import UNIVERSAL from "../../../config";
import {
  SET_COMPANY_DATA,
  SET_ELASTIC_ORGANIZATIONS,
  SET_TOTAL_COMPANIES,
  SET_CONTROVERSY_LINKS,
  SET_PAGINATION_PAGE,
  SET_SURVEY_DATA,
  SET_SURVEY_PAGINATION_PAGE,
  SET_SELECTED_SURVEY,
  SET_SELECTED_SURVEY_RESPONSE,
  SET_SELECTED_RES_COMPANY,
  SET_SECTION,
  SET_QUESTIONS,
  SET_SELECTED_SECTION,
  SET_RESPONDED_ORGAIZATIONS,
  SET_SURVEY_RESPONDED_USERS,
  SET_SUBMITTED_QUESTIONS,
} from "../../../constants/riskDashboardConstants";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  getCompanyInsights,
  getControversyData,
  setCompanyInsights,
} from "../riskEvaluator/riskEvaluatorActions";
import encrypt from "../shared/sharedActions";
import { set_snack_bar } from "../snackbar/snackbar_action";

export const setElasticOrganizations = (payload) => {
  return {
    type: SET_ELASTIC_ORGANIZATIONS,
    payload,
  };
};

export const setTotalCompanies = (payload) => {
  return {
    type: SET_TOTAL_COMPANIES,
    payload,
  };
};

export const setCompanyData = (payload) => {
  return {
    type: SET_COMPANY_DATA,
    payload,
  };
};

export const setControversyLinks = (payload) => {
  return {
    type: SET_CONTROVERSY_LINKS,
    payload,
  };
};

export const setDashboardPage = (payload) => {
  return {
    type: SET_PAGINATION_PAGE,
    payload,
  };
};

export function getElasticOrganizations(
  token,
  limit,
  starting_after,
  keyword = ""
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      limit,
      starting_after,
      keyword,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/get_list_of_organizations",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setElasticOrganizations(responseJson.result));
          dispatch(setTotalCompanies(responseJson.total));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getCompanyData(token, company_id) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      company_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_company_data", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setCompanyData(responseJson.result));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateControversyType(
  token,
  id,
  controversy_type,
  isin,
  setCurrentLink,
  sentiment_label,
  year
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      id,
      controversy_type,
      sentiment_label,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/update_company_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          const size = 5;
          const from = 0;
          id && dispatch(getControversyData(token, isin));
          id && dispatch(getControversyLinks(token, isin, size, from,year));

          id && dispatch(setCurrentLink(""));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function searchArticles(token, isin, year, size = 5, from = 0) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      isin,
      year,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/python_apis/update_controversy_process_completed",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getControversyLinks(token, isin, size, from,year));

          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function publishLinks(token, ids, isin, size, from, year) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      ids: ids.ids,
      year: year,
      isin: isin,
    });

    console.log(isin);
    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/update_company_publish_details",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(getControversyLinks(token, isin, size, from,year));
          dispatch(getControversyData(token, isin));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateCompanyData(
  token,
  company_id,
  isin,
  year,
  answers,
  setAdminTabNo
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      company_id,
      isin,
      year,
      answers,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/update_organization_data",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getCompanyData(token, company_id));
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          setAdminTabNo(1);
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getControversyLinks(token, isin, size, from,year) {
  return (dispatch) => {
    // "eyJhbGciOiJIUzI1NiJ9.dGhpa3NobmFAYXBwc3RvbmUuaW4._gdKET5wDie_Abuts4XEDlfm0idpeInVg2-JvPCLQ3w"
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      isin,
      size,
      from,
      year
    });
    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/get_company_details", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(
            setControversyLinks({
              payload: responseJson.result,
              total: responseJson.total,
              is_published: responseJson.is_published,
            })
          );
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(
            setControversyLinks({
              payload: [],
              total: 0,
              is_published: false,
            })
          );
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function regenerateInsights(token, isin, id, year) {
  return (dispatch) => {
    // dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      isin: isin || "INE002A01018",
      year: year || "2021-2022",
    });
    return fetch(UNIVERSAL.BASEURL + "/python_apis/regenerate_insignts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getCompanyInsights(token, isin, year));
          // dispatch(unset_loader());
          // dispatch(
          //   setTimeout(() => {
          //     dispatch(getCompanyInsights(token, isin, year));
          //   }, 16000)
          // );
          // dispatch(getCompanyInsights(token, isin));
          // dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function deleteInsightWidget(
  token,
  id,
  updatedObject,
  areaObject,
  isin,
  year
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      _id: id,
      updated_object: updatedObject,
      area_object: areaObject,
    });
    return fetch(UNIVERSAL.BASEURL + "/python_apis/delete_insight_widget", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getCompanyInsights(token, isin, year));
          dispatch(unset_loader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function editInsightWidget(
  token,
  id,
  updatedObject,
  areaObject,
  isin,
  year,
  insightLogo
) {
  console.log(updatedObject, areaObject, insightLogo);
  return (dispatch) => {
    dispatch(set_loader(true));

    const data = encrypt({
      "user-token": token,
      _id: id,
      updated_object: updatedObject,
      area_object: areaObject,
    });

    var formData = new FormData();

    formData.append("data", data);

    insightLogo && formData.append("logo", insightLogo);

    return fetch(UNIVERSAL.BASEURL + "/python_apis/edit_insight_widget", {
      method: "POST",
      // headers: {
      //   Accept: "application/json",
      //   "Content-Type": "application/json",
      // },
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(getCompanyInsights(token, isin, year));
          dispatch(unset_loader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        } else {
          dispatch(unset_loader());
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function publishInsights(token, isin, id, year) {
  return (dispatch) => {
    // "eyJhbGciOiJIUzI1NiJ9.dGhpa3NobmFAYXBwc3RvbmUuaW4._gdKET5wDie_Abuts4XEDlfm0idpeInVg2-JvPCLQ3w"
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      isin,
      year,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/elastic_search2/publish_company_insights",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          // setTimeout(() => {
          dispatch(getCompanyInsights(token, isin, year));
          // }, 1500);
          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

// ----------- Survey Actions -----------
export const addSurveyData = (surveyData) => {
  return {
    type: SET_SURVEY_DATA,
    payload: surveyData,
  };
};

export const setSelectedSurvey = (selectedSurvey) => {
  return {
    type: SET_SELECTED_SURVEY,
    payload: selectedSurvey,
  };
};

export const setSurveyPage = (payload) => {
  return {
    type: SET_SURVEY_PAGINATION_PAGE,
    payload,
  };
};

// ----------- Survey Inner Actions -----------
export const setSurveyResponse = (selectedResponse) => {
  return {
    type: SET_SELECTED_SURVEY_RESPONSE,
    payload: selectedResponse,
  };
};

export const setSubmittedQuestions = (
  selectedResponse,
  total_no_of_questions,
  is_completed
) => {
  return {
    type: SET_SUBMITTED_QUESTIONS,
    payload: selectedResponse,
    total_no_of_questions,
    is_completed,
  };
};

export const setSurveyRespondedUsers = (selectedResponse) => {
  return {
    type: SET_SURVEY_RESPONDED_USERS,
    payload: selectedResponse,
  };
};

export const setResponseCompany = (selectedResCompany) => {
  return {
    type: SET_SELECTED_RES_COMPANY,
    payload: selectedResCompany,
  };
};

export const setRespondedOrganizastions = (selectedResCompany) => {
  return {
    type: SET_RESPONDED_ORGAIZATIONS,
    payload: selectedResCompany,
  };
};

//   const setOrganizationsList = (payload) => {
//     return {
//       type: SET_ORGANIZATIONS_LIST,
//       payload,
//     };
//   };

export const setSection = (payload) => {
  return {
    type: SET_SECTION,
    payload,
  };
};

export const setQuestions = (payload) => {
  return {
    type: SET_QUESTIONS,
    payload,
  };
};

export const setSelectedSection = (payload) => {
  return {
    type: SET_SELECTED_SECTION,
    payload,
  };
};

export function getSurveySectionQuestion(
  token,
  sectionId,
  is_organization_based = false
) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      is_organization_based,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_survey_sections", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(setSection(responseJson?.result));

          if (sectionId) {
            dispatch(
              setSelectedSection(
                responseJson?.result.find((s) => s._id === sectionId)?.title
              )
            );
          }

          // }, 1500);
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateQuestion(
  token,
  question_id,
  weightage,
  question,
  active,
  answers,
  question_type,
  section_id,
  comment,
  mandatory,
  latestVersion
) {
  return (dispatch, getState) => {
    dispatch(set_loader(true));
    const { sectionWiseQuestion, offset } = getState()?.riskDashboard;
    const data = encrypt({
      "user-token": token,
      question_id,
      weightage,
      question,
      active,
      answers,
      question_type,
      comment,
      mandatory,
      latestVersion,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/update_question", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          // setTimeout(() => {
          dispatch(
            getQuestion(
              token,
              section_id,
              10 + offset,
              0,
              sectionWiseQuestion,
              false
            )
          );
          // }, 1500);
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getQuestion(
  token,
  section_id,
  limit = 10,
  starting_after = 0,
  sectionWiseQuestion,
  hasOldData = true
) {
  return (dispatch) => {
    // dispatch(setQuestions([]));
    // dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      section_id,
      limit,
      starting_after,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/get_questions", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          // setTimeout(() => {
          // dispatch(getSurveySectionQuestion(token));
          // }, 1500);
          hasOldData
            ? dispatch(
                setQuestions([...sectionWiseQuestion, ...responseJson?.result])
              )
            : dispatch(setQuestions([...responseJson?.result]));
          dispatch({ type: "HAS_MORE_DATA", payload: true });
          // dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch({ type: "HAS_MORE_DATA", payload: false });

          // dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getSurveyResponses(token) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/survey/get_organizations_that_submitted_answers",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setRespondedOrganizastions(responseJson?.result));
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
export function getSubmittedResponse(
  token,
  organization_id,
  frequency_id,
  user_id,
  version
) {
  return (dispatch) => {
    // dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      organization_id,
      frequency_id,
      user_id,
      version,
    });

    return fetch(UNIVERSAL.BASEURL + "/survey/get_submitted_response", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(unset_loader());

          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(
            setSubmittedQuestions(
              responseJson?.result,
              responseJson?.total_no_of_questions,
              responseJson?.is_completed
            )
          );
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function getSurveyRespondedUser(token, organization_id) {
  return (dispatch) => {
    // dispatch(setQuestions([]));
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      organization_id,
    });
    return fetch(UNIVERSAL.BASEURL + "/assign_member/get_assigned_users", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(setSurveyRespondedUsers(responseJson?.result));
          dispatch(unset_loader());

          // dispatch(setResponseCompany(responseJson?.result[0]));
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function addQuestion(
  token,
  question,
  question_type,
  section_id = "",
  answers,
  version,
  order
) {
  console.log(answers);
  return (dispatch, getState) => {
    // dispatch(setQuestions([]));
    // dispatch(set_loader(true));
    const { sectionWiseQuestion, offset } = getState()?.riskDashboard;

    const data = encrypt({
      "user-token": token,
      question,
      question_type,
      section_id,
      answers,
      order,
      version,
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/add_question", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          // setTimeout(() => {
          // dispatch(getSurveySectionQuestion(token));
          // }, 1500);
          dispatch(
            getQuestion(
              token,
              responseJson?.result || section_id,
              10 + offset,
              0,
              sectionWiseQuestion,
              false
            )
          );
          if (responseJson?.result) {
            dispatch(getSurveySectionQuestion(token, responseJson?.result));
          }
          // dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function deleteSection(token, section_id) {
  return (dispatch) => {
    // dispatch(setQuestions([]));
    // dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      section_id,
    });
    return fetch(
      UNIVERSAL.BASEURL + "/survey/delete_survey_section_and_questions",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(getSurveySectionQuestion(token));

          // dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function updateSection(token, section) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      section_id: section["_id"],
      title: section["title"],
      mandatory: section["mandatory"],
      show: section["show"],
    });
    return fetch(UNIVERSAL.BASEURL + "/survey/edit_survey_section", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          // setTimeout(() => {
          dispatch(getSurveySectionQuestion(token, section["_id"]));
          // dispatch(setSelectedSection(title))
          // }, 1500);
          dispatch(unset_loader());
        } else {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));

          dispatch(unset_loader());
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function publish_company(token, isin, year, company_id) {
  return (dispatch) => {
    dispatch(set_loader(true));
    const data = encrypt({
      "user-token": token,
      isin,
      year,
      company_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/elastic_search2/publish_company", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // dispatch(getControversyLinks(token, isin, size, from));

          dispatch(set_snack_bar(responseJson.status, responseJson.message));
        }
        dispatch(unset_loader());
      })
      .catch((error) => {
        console.log(error);
      });
  };
}
